import React from 'react'
import { useSelector } from 'react-redux'
import { Group } from '@revolut/ui-kit'
import { useGlobalSettings } from '@src/api/settings'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { selectPermissions, selectFeatureFlags } from '@src/store/auth/selectors'
import { employeeSettingsLinkConfig } from './config'
import { getSettingsLinkConfig } from '../common/config'

export const GeneralSettings = () => {
  const { settings } = useGlobalSettings()
  const permissions = useSelector(selectPermissions)
  const featureFlags = useSelector(selectFeatureFlags)

  const config = getSettingsLinkConfig({
    config: employeeSettingsLinkConfig,
    permissions,
    featureFlags,
    globalSettings: settings,
    type: 'settings',
  })

  return (
    <>
      <PageHeader
        backUrl={ROUTES.SETTINGS.ALL}
        subtitle="All settings for employee work and personal profiles, employee groups and custom fields"
        title="Employee app settings"
      />
      <PageBody>
        <Group mb="s-24">
          {config.employeeProfile?.component}
          {config.employeeAttributes?.component}
          {config.employeeCompensationSettings?.component}
          {config.employeeLifecycleSettings?.component}
          {config.employeeDataRetentionSettings?.component}
          {config.employeeOnboardingSettings?.component}
          {config.employeeLocations?.component}
          {config.employeeEntities?.component}
          {config.employeeGroups?.component}
        </Group>
      </PageBody>
    </>
  )
}
